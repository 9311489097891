<template>
	<Dialog :dialog="reservationDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Reservation {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="reservationForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-layout>
					<v-flex>
						<v-row class="reservation-dialog">
							<v-col md="12" v-if="relatedDetails">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll"
									style="max-height: 60vh; position: static"
								>
									<v-list class="mx-2 mx-md-4 border border-secondary" subheader three-line>
										<v-list-item>
											<v-list-item-avatar v-if="relatedDetails">
												<ImageTemplate circle :src="relatedDetails.image"></ImageTemplate>
											</v-list-item-avatar>
											<v-list-item-content v-if="relatedDetails">
												<v-list-item-title class="font-size-16">
													<Chip :text="relatedDetails.barcode"></Chip>
												</v-list-item-title>
												<v-list-item-subtitle class="font-size-14">
													<b>{{ relatedDetails.name }}</b>
												</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action class="margin-auto" v-if="relatedDetails">
												<v-list-item-action-text class="font-size-16"
													><b>Cost</b> - {{ relatedDetails.cost_price_formatted }}
												</v-list-item-action-text>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</perfect-scrollbar>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label :for="`reservation-duration-${uniqFieldId}`" class="btx-label mt-3">Duration</label>
							</v-col>
							<v-col md="9" class="py-0">
								<v-layout>
									<v-flex md6>
										<NumberInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`reservation-duration-${uniqFieldId}`"
											placeholder="Duration"
											v-model="reservation.duration"
											v-on:keyup="updateDuration()"
										></NumberInput>
									</v-flex>
									<v-flex md6>
										<SelectInput
											hide-details
											:items="durationList"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="Duration"
											v-model="reservation.duration_type"
											v-on:change="updateDuration()"
										></SelectInput>
									</v-flex>
								</v-layout>
							</v-col>
							<!-- <v-col md="3" class="py-0">
							<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3"
								>Approval Required</label
							>
						</v-col>
						<v-col md="9" class="py-0">
							<RadioInput
								row
								hide-details
								v-model="reservation.aprrove_transfer"
								:disabled="pageLoading"
								:id="`service-performed-by-${uniqFieldId}`"
								:items="approvalIteam"
							></RadioInput>
						</v-col> -->
							<v-col md="3" class="my-auto py-0">
								<label :for="`reservation-from-${uniqFieldId}`" class="btx-label mt-3 required">From</label>
							</v-col>
							<v-col md="9" class="py-0">
								<DateTimePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`reservation-from-${uniqFieldId}`"
									placeholder="From"
									v-model="reservation.from"
									v-on:change="updateDuration()"
								></DateTimePicker>
							</v-col>
							<v-col md="3" class="my-auto py-0">
								<label :for="`reservation-to-${uniqFieldId}`" class="btx-label mt-3 required">To</label>
							</v-col>
							<v-col md="9" class="py-0">
								<DateTimePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`reservation-to-${uniqFieldId}`"
									placeholder="To"
									v-model="reservation.to"
								></DateTimePicker>
							</v-col>
							<v-col md="3" class="py-0"> </v-col>
							<v-col md="9" class="py-0 d-flex">
								<v-checkbox
									dense
									hide-details
									:disabled="pageLoading"
									v-model="reservation.overwrite"
									label="Overwrite Conflicting Reservation"
									class="mt-3 mb-0 p-0"
									:true-value="1"
									:false-value="0"
								></v-checkbox>
								<TooltipQuestion content-class="mt-3">
									<template v-slot:text>Conflicting reservation will be overwritten.</template>
								</TooltipQuestion>
							</v-col>
							<template v-if="endpoint == 'asset-stock'">
								<v-col md="3" class="my-auto py-0">
									<label :for="`quantity-${uniqFieldId}`" class="btx-label mt-3 required">
										Quantity
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<span class="font-level-1-bold" v-bind="attrs" v-on="on"
													>({{ relatedDetails.stock_level }})</span
												>
											</template>
											<span> Available Quantity</span>
										</v-tooltip>
									</label>
								</v-col>
								<v-col md="9" class="py-0">
									<QuantityInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`quantity-${uniqFieldId}`"
										placeholder="Quantity"
										v-model="reservation.quantity"
										:rules="[vrules.required(reservation.quantity, 'Quantity')]"
										:class="{
											required: !reservation.quantity,
										}"
									></QuantityInput>
								</v-col>
							</template>
							<template v-if="false">
								<v-col md="3" class="my-auto py-0">
									<label :for="`reservation-from-${uniqFieldId}`" class="btx-label mt-3 required"
										>Reservation From</label
									>
								</v-col>
								<v-col md="9" class="py-0">
									<AutoCompleteInput
										hide-details
										item-text="location_name"
										item-value="location"
										:items="assetLocationList"
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`reservation-from-${uniqFieldId}`"
										placeholder="Location"
										v-model="reservation.location_from"
										v-on:change="setAvailableQTY()"
										:rules="[vrules.required(reservation.location_from, 'Reservation From')]"
										:class="{
											required: !reservation.location_from,
										}"
									></AutoCompleteInput>
								</v-col>
							</template>
							<v-col md="3" class="my-auto py-0">
								<label :for="`reservation-for-${uniqFieldId}`" class="btx-label mt-3"
									>Reservation For</label
								>
							</v-col>
							<v-col md="9" class="py-0">
								<AutoCompleteInputMember
									hide-details
									:items="memberList"
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`reservation-for-${uniqFieldId}`"
									:rules="[vrules.required(reservation.member, 'Group')]"
									:class="{
										required: !reservation.member,
									}"
									placeholder="Staff"
									v-on:click:append-outer="manageMember()"
									v-model="reservation.member"
								></AutoCompleteInputMember>
							</v-col>
							<template v-if="false">
								<v-col md="3" class="my-auto py-0">
									<label :for="`location-${uniqFieldId}`" class="btx-label mt-3">
										<template v-if="endpoint == 'asset'">Location</template>
										<template v-else>Reserved To</template>
									</label>
								</v-col>
								<v-col md="9" class="py-0">
									<AutoCompleteInput
										hide-details
										:items="locationList"
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`location-${uniqFieldId}`"
										placeholder="Location"
										append-outer-icon="mdi-cog"
										v-on:click:append-outer="manageLocation()"
										v-model="reservation.location"
									></AutoCompleteInput>
								</v-col>
							</template>
							<v-col md="3" class="py-0">
								<label :for="`comment-${uniqFieldId}`" class="btx-label mt-3">Comments</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`comment-${uniqFieldId}`"
									placeholder="Comments"
									v-model="reservation.comment"
								></TextAreaInput>
							</v-col>
						</v-row>
					</v-flex>
					<v-flex class="ml-8">
						<v-col md="12" class="font-level-3-bold pb-3 text-black mt-4 py-0 px-0">
							Other Reservations
						</v-col>
						<v-date-picker
							class="border-light-grey"
							color="blue darken-4"
							v-model="dates"
							range
							no-title
							readonly
							multiple
						></v-date-picker>
					</v-flex>
				</v-layout>
			</v-form>
		</template>
		<template v-slot:action>
			<template>
				<template v-if="cartUuid">
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="updateCart()"
					>
						Update Cart
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="addToCart()"
					>
						Add To Cart
					</v-btn>
				</template>
			</template>
			<template v-if="false">
				<template v-if="reservationUuid">
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onUpdateSubmit()"
					>
						Reserve Update
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Reserve
					</v-btn>
				</template>
			</template>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger, concat, uniq, find } from "lodash";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import AutoCompleteInputMember from "@/view/components/AutoCompleteInputMember";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import NumberInput from "@/view/components/NumberInput";
import QuantityInput from "@/view/components/QuantityInput";
import DateTimePicker from "@/view/components/DateTimePicker";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { EventBus } from "@/core/event-bus/event.bus";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import { CART_COUNT } from "@/core/services/store/config.module";
import store from "@/core/services/store";
import ObjectPath from "object-path";
import MomentJS from "moment-timezone";
//import RadioInput from "@/view/components/RadioInput";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "reservation-template",
	title: "Reservation Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		reservationDialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
		cartUuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		reservationDialog(param) {
			if (param) {
				this.initReservation();
			}
		},
		typeUuid: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param && !this.uuid) {
					this.getRelatedDetails(this.type, param);
				}
			},
		},
	},
	data() {
		return {
			formValid: true,
			reservationUuid: null,
			pageLoading: false,
			assetLocationList: [],
			locationList: [],
			relatedDetails: {},
			memberList: [],
			dates: [],
			available_quantity: 0,
			available_quantity_formatted: 0,
			reservation: {
				duration: 1,
				duration_type: "week",
				from: null,
				to: null,
				quantity: 0,
				location_from: 0,
				//aprrove_transfer:1,
				member: 0,
				location: 0,
				comment: null,
				overwrite: 0,
			},
			/* approvalIteam: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 0,
				},
			], */
		};
	},
	components: {
		Dialog,
		NumberInput,
		QuantityInput,
		ImageTemplate,
		Chip,
		TooltipQuestion,
		DateTimePicker,
		TextAreaInput,
		SelectInput,
		AutoCompleteInput,
		AutoCompleteInputMember,
		//	RadioInput
	},
	methods: {
		getRelatedDetails(type, typeUuid) {
			let url = "";
			if (type == "Asset") {
				url = `asset/${typeUuid}`;
			} else {
				url = `asset-stock/${typeUuid}`;
			}

			this.pageLoading = true;
			ApiService.get(url)
				.then(({ data }) => {
					this.relatedDetails = data;
					if (type != "Asset") {
						this.relatedDetails.cost_price_formatted = data.price_add_stock_formatted;
					}
				})
				.catch((error) => {
					//this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		setAvailableQTY() {
			if (this.reservation && this.reservation.location_from) {
				const location = find(this.assetLocationList, { location: this.reservation.location_from });
				if (location) {
					this.available_quantity = location.quantity;
					this.available_quantity_formatted = location.quantity_formatted;
				}
			}
		},
		initReservation() {
			if (this.endpoint == "asset-stock") {
				this.initAsset();
			}
			const today = new Date();
			const nextWeek = MomentJS().add(7, "days");

			this.dates = [];

			this.pageLoading = true;

			this.getReservations();

			this.reservation = new Object({
				duration: 1,
				duration_type: "week",
				from: this.formatDateTimeRaw(today),
				to: this.formatDateTimeRaw(nextWeek),
				member: 0,
				location: 0,
				comment: null,
				//aprrove_transfer: 1,
				overwrite: 0,
			});
			if (this.uuid) {
				this.getReservation();
			}
			if (this.cartUuid) {
				this.getCartCheckedOut();
			}
		},
		initAsset() {
			/*if (this.endpoint && this.typeUuid) {
				ApiService.get(`${this.endpoint}/${this.typeUuid}/location`)
					.then(({ data }) => {
						this.assetLocationList = data;
						this.setAvailableQTY();
					})
					.catch((error) => {
						this.$emit("error", error);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}*/
		},
		getCartCheckedOut() {
			this.pageLoading = true;
			ApiService.get(`cart/${this.cartUuid}`)
				.then(({ data }) => {
					this.reservation = new Object({
						duration: 1,
						duration_type: "week",
						from: this.formatDateTimeRaw(data.reserve_from),
						to: this.formatDateTimeRaw(data.reserve_to),
						quantity: data.quantity,
						member: data.member,
						location: data.location_to,
						comment: data.description,
						//	aprrove_transfer : data.aprrove_transfer,
						overwrite: data.ignore_conflict,
					});
					this.endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					this.typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.type = data.asset && data.asset.id > 0 ? "Asset" : "asset-stock";

					this.getRelatedDetails(this.type, this.typeUuid);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getReservation() {
			this.pageLoading = true;
			ApiService.get(`reservation/${this.uuid}`)
				.then(({ data }) => {
					const endpoint = data.asset && data.asset.id > 0 ? "asset" : "asset-stock";
					const typeUuid = data.asset && data.asset.id > 0 ? data.asset.uuid : data.asset_stock.uuid;
					this.$emit("endpoint", endpoint);
					this.$emit("typeUuid", typeUuid);

					if (endpoint == "asset") {
						this.relatedDetails = ObjectPath.get(data, "asset");
					}

					if (endpoint == "asset-stock") {
						this.relatedDetails = ObjectPath.get(data, "asset_stock");
					}

					this.$nextTick(() => {
						this.reservationUuid = data.uuid;
						this.getReservations();
						this.initAsset();
						this.reservation = {
							duration: 1,
							duration_type: "week",
							from: data.reserve_from,
							to: data.reserve_to,
							quantity: data.quantity,
							location_from: data.location_from,
							member: data.member,
							location: data.location_to,
							comment: data.description,
							//aprrove_transfer : data.aprrove_transfer,
							overwrite: data.overwrite,
						};
					});
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDuration() {
			let days = 1;

			let { duration_type, duration, from } = this.reservation;

			switch (duration_type) {
				case "day":
					days = toSafeInteger(duration);
					break;
				case "week":
					days = 7 * toSafeInteger(duration);
					break;
			}

			if (!from) {
				from = new Date();
				this.reservation.from = this.formatDateTimeRaw(from);
			}

			this.$nextTick(() => {
				const date = MomentJS(from).add(days, "days");
				this.reservation.to = this.formatDateTimeRaw(date);
			});
		},
		manageMember() {
			console.log("manageMember");
		},
		manageLocation() {
			console.log("manageLocation");
		},
		initRanges(reservations) {
			let _this = this;

			let dates = [];

			for (let i = 0; i < reservations.length; i++) {
				let interval = this.eachDayOfInterval({
					start: new Date(reservations[i].reserve_from),
					end: new Date(reservations[i].reserve_to),
				});
				dates = concat(dates, interval);
			}

			dates = dates.map((row) => {
				return _this.formatDateRaw(row);
			});

			this.dates = uniq(dates);
		},
		getReservations() {
			if (this.endpoint && this.typeUuid) {
				ApiService.query(`${this.endpoint}/${this.typeUuid}/reservation`, { status: "reserved" })
					.then(({ data }) => {
						this.initRanges(data);
					})
					.catch((error) => {
						this.$emit("error", error);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.reservationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.reservationForm.validate()) {
				return false;
			}

			if (this.endpoint == "asset-stock") {
				const available_quantity = toSafeInteger(this.relatedDetails.stock_level);
				const quantity = toSafeInteger(this.reservation.quantity);
				if (available_quantity < quantity) {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: `Quantity (${quantity}) must be less then available quantity (${available_quantity})`,
						},
					]);
					return false;
				}
			}

			this.pageLoading = true;

			ApiService.patch(`${this.endpoint}/${this.typeUuid}/reservation`, this.reservation)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-reservation", true);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.reservationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.reservationForm.validate()) {
				return false;
			}

			if (this.endpoint == "asset-stock") {
				const available_quantity = toSafeInteger(this.relatedDetails.stock_level);
				const quantity = toSafeInteger(this.reservation.quantity);
				if (available_quantity < quantity) {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: `Quantity (${quantity}) must be less then available quantity (${available_quantity})`,
						},
					]);
					return false;
				}
			}

			this.pageLoading = true;

			ApiService.put(
				`${this.endpoint}/${this.typeUuid}/reservation/${this.reservationUuid}`,
				this.reservation
			)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-reservation", true);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addToCart(replace = false) {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.reservationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.reservationForm.validate()) {
				return false;
			}

			if (this.endpoint == "asset-stock") {
				const available_quantity = toSafeInteger(this.relatedDetails.stock_level);
				const quantity = toSafeInteger(this.reservation.quantity);
				if (available_quantity < quantity) {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: `Quantity (${quantity}) must be less then available quantity (${available_quantity})`,
						},
					]);
					return false;
				}
			}

			const reservationData = {
				reserve_from: this.reservation.from,
				reserve_to: this.reservation.to,
				duration: this.reservation.duration,
				ignore_conflict: this.reservation.overwrite,
				duration_type: this.reservation.duration_type,
				member: this.reservation.member,
				comment: this.reservation.comment,
				//aprrove_transfer : this.reservation.aprrove_transfer,
				quantity: this.reservation.quantity,
				location_from: this.reservation.location_from,
				related_type: this.type,
				replace: replace,
				indefinitely: false,
			};

			this.pageLoading = true;

			ApiService.post(`cart/${this.typeUuid}/reservation`, reservationData)
				.then(({ data }) => {
					if (data && data.exists) {
						this.$emit("close", true);
						this.$swal({
							title: "Another Staff details exist in cart, Do you want to replace it?",
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Replace",
							confirmButtonClass: "bg-danger",
						}).then((result) => {
							if (result.isConfirmed) {
								this.addToCart(true);
							}
						});
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${this.type} has been successfully added to cart.` },
						]);
						this.$emit("success");
						EventBus.$emit("reload:asset-reservation", true);
						store.dispatch(CART_COUNT);
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateCart(replace = false) {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.reservationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.reservationForm.validate()) {
				return false;
			}

			if (this.endpoint == "asset-stock") {
				const available_quantity = toSafeInteger(this.available_quantity);
				const quantity = toSafeInteger(this.reservation.quantity);
				if (available_quantity < quantity) {
					this.$store.commit(SET_ERROR, [
						{
							model: true,
							message: `Quantity (${quantity}) must be less then available quantity (${available_quantity})`,
						},
					]);
					return false;
				}
			}

			const reservationData = {
				reserve_from: this.reservation.from,
				reserve_to: this.reservation.to,
				duration: this.reservation.duration,
				ignore_conflict: this.reservation.overwrite,
				duration_type: this.reservation.duration_type,
				member: this.reservation.member,
				comment: this.reservation.comment,
				//aprrove_transfer : this.reservation.aprrove_transfer,
				quantity: this.reservation.quantity,
				location_from: this.reservation.location_from,
				related_type: this.type,
				replace: replace,
				indefinitely: false,
			};

			this.pageLoading = true;

			ApiService.put(`cart/${this.typeUuid}/reservation/${this.cartUuid}`, reservationData)
				.then((data) => {
					if (data && data.exists) {
						this.$emit("close", true);
						this.$swal({
							title: "Another member details exist in cart, Do you want to replace it?",
							icon: "question",
							showCancelButton: true,
							confirmButtonText: "Replace",
							confirmButtonClass: "bg-danger",
						}).then((result) => {
							if (result.isConfirmed) {
								this.updateCart(true);
							}
						});
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${this.type} has been successfully added to cart.` },
						]);
						this.$emit("success");
						EventBus.$emit("reload:asset-reservation", true);
						store.dispatch(CART_COUNT);
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		this.memberList = this.localDB("members", []);
		this.initReservation();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 65);
		},
	},
};
</script>
