<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<h1 class="custom-header-blue-text mt-2">Reservation</h1>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="getPermission('checkout:create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						v-on:click="
							selectRelateddialog = true;
							uuid = null;
						"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						v-if="false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('group')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('group')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Related #, Name, Staff"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="reservation"
			dialog-update
			delete-endpoint="reservation/"
			v-on:reload:content="filterRows"
			v-on:createCheckInOut="createCheckout"
			v-on:update:dialog="updateDialog($event)"
			delete-note="All transactions of this group will also be deleted."
		></Table>
		<SelectRequested
			v-on:selectRelatedTo="selectRelatedTo"
			v-on:close="selectRelateddialog = false"
			:checkout-dialog="selectRelateddialog"
		></SelectRequested>
		<ReservationTemplate
			:type="relatedType"
			:endpoint="relatedEndPoint"
			:type-uuid="relatedUuid"
			:uuid="uuid"
			v-on:success="
				reservationDialog = false;
				relatedUuid = null;
				relatedType = null;
				relatedEndPoint = null;
				getListing();
			"
			v-on:close="reservationDialog = false"
			v-on:endpoint="(param) => (relatedEndPoint = param)"
			v-on:typeUuid="(param) => (relatedUuid = param)"
			:reservation-dialog="reservationDialog"
		></ReservationTemplate>
		<CheckoutTemplate
			:type="relatedTypeCheckOut"
			:endpoint="relatedEndPointCheckOut"
			:type-uuid="relatedUuidCheckOut"
			:reservation-uuid="reservationUuid"
			v-on:success="
				checkOutDialog = false;
				relatedUuidCheckOut = null;
				relatedTypeCheckOut = null;
				relatedEndPointCheckOut = null;
				getListing();
			"
			v-on:close="checkOutDialog = false"
			:checkout-dialog="checkOutDialog"
		></CheckoutTemplate>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import ListingMixin from "@/core/mixins/listing.mixin";
import SelectRequested from "@/view/components/Select-Checkout-Requested";
import CheckoutTemplate from "@/view/components/CheckoutTemplate";
import ReservationTemplate from "@/view/components/ReservationTemplate";
import { find } from "lodash";

export default {
	name: "group-listing",
	title: "Listing Group",
	mixins: [ListingMixin],
	components: {
		SelectRequested,
		CheckoutTemplate,
		ReservationTemplate,
	},
	methods: {
		updateDialog(uuid) {
			const row = find(this.tbody, { uuid });
			if (uuid) {
				this.uuid = uuid;
				this.relatedType = row.type;
				this.relatedEndPoint = row.type == "Asset" ? "asset" : "asset-stock";
				this.relatedUuid = row.type == "Asset" ? row.asset_uuid : row.asset_stock_uuid;
				this.reservationDialog = true;
			}
		},
		selectRelatedTo(data) {
			this.relatedUuid = data.id;
			this.relatedType = data.type;
			this.relatedEndPoint = data.endpoint;
			if (this.relatedUuid && this.relatedType && this.relatedEndPoint) {
				this.selectRelateddialog = false;
				this.reservationDialog = true;
			}
		},
		createCheckout(data) {
			this.reservationUuid = data.uuid;
			this.relatedUuidCheckOut = data.asset > 0 ? data.asset_uuid : data.asset_stock_uuid;
			this.relatedTypeCheckOut = data.asset > 0 ? "Asset" : "Asset Stock";
			this.relatedEndPointCheckOut = data.asset > 0 ? "asset" : "asset-stock";
			this.checkOutDialog = true;
		},
	},
	data() {
		return {
			uuid: null,
			dialog: false,
			relatedUuid: null,
			checkOutDialog: false,
			reservationDialog: false,
			relatedType: null,
			status: "reserved",
			relatedEndPoint: null,
			reservationUuid: null,
			relatedUuidCheckOut: null,
			relatedTypeCheckOut: null,
			relatedEndPointCheckOut: null,
			selectRelateddialog: false,
			pageTitle: "Reservation",
			pageBreadcrumbs: [{ text: "Reservation", disabled: true }],
			endpoint: "reservation",
			defaultFilter: { status: "reserved" },
		};
	},
	computed: {
		...mapGetters(["tbody"]),
	},
};
</script>
